<template>
  <div class="dashboard-layout">
    <div class="d-flex mb-3">
      <slot name="header" />
      <base-entity-selection
        v-if="!hideTeam"
        class="align-self-center ml-2"
        loadingPrompt="Loading teams..."
        v-model="selectedSimulationTeam"
        :disabled="hasOneSimulationTeam"
        :items="teams"
        :loading="loadingSimulationTeams"
        @changed="onSimulationTeamChange"
      ></base-entity-selection>
      <p v-if="!hideWeekDay" class="align-self-center p-0 m-0 mx-2">
        <b-overlay :show="loadingTeamWeekDays" rounded="xs" spinner-small spinner-variant="primary">
          <b-badge v-if="openTeamWeek" variant="outline-info">
            {{ openTeamWeek.open_week_name }}
          </b-badge>
          <b-badge v-if="loadingTeamWeekDays && openTeamWeek === null">
            <span>loading...</span>
          </b-badge>
        </b-overlay>
      </p>
      <p v-if="!hideWeekDay" class="align-self-center p-0 m-0">
        <b-overlay :show="loadingTeamWeekDays" rounded="xs" spinner-small spinner-variant="primary">
          <b-badge v-if="openTeamWeek" variant="outline-new" :disabled="loadingTeamWeekDays">
            {{ openTeamWeek.open_day_name }}
          </b-badge>
          <b-badge v-if="loadingTeamWeekDays && openTeamWeek === null">
            <span>loading...</span>
          </b-badge>
        </b-overlay>
      </p>
      <div class="ml-auto">
        <slot name="header-right" />
      </div>
    </div>
    <div class="dashboard-body dashboard-body-scroll pr-3">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { managementMixin } from '@/mixins/managementMixin'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  props: {
    hideTeam: {
      type: Boolean,
      default: false
    },
    hideWeekDay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fill: variables.primaryColor,
      selectedSimulationTeam: null,
      teams: []
    }
  },
  mixins: [managementMixin],
  methods: {
    ...mapActions(['fetchOpenTeamWeek']),
    ...mapMutations(['setCurrentSimulationTeam']),
    async onSimulationTeamChange(item) {
      this.selectedSimulationTeam = item
      this.setCurrentSimulationTeam(item)
    }
  },
  computed: {
    ...mapGetters([
      'currentSimulation',
      'currentSimulationTeam',
      'openTeamWeek',
      'simulationTeams'
    ]),
    ...mapGetters('loading', ['loadingSimulationTeams', 'loadingTeamWeekDays']),
    hasOneSimulationTeam() {
      return this.simulationTeams.length === 1
    },
    sortedSimulationTeams() {
      return this.teams.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    }
  },
  watch: {
    selectedSimulationTeam: {
      async handler(newValue) {
        if (newValue) {
          await this.fetchOpenTeamWeek({
            simulationId: this.currentSimulation.id,
            teamId: newValue.id
          })
        }
      },
      immediate: true
    },
    simulationTeams: {
      async handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.teams = await _.cloneDeep(newValue)
          const simulationTeam = (await this.currentSimulationTeam)
            ? this.currentSimulationTeam
            : this.sortedSimulationTeams[0]
          this.onSimulationTeamChange(simulationTeam)
        } else {
          this.selectedSimulationTeam = null
          this.teams = []
          await this.setCurrentSimulationTeam(null)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-body-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 150px);
}
</style>
